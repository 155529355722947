import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { getDailyAQI } from "../../api/getDailyAQI";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { RootState } from "../../app/store";
import { GoogleMapBox } from "../../components/Map";
import { ParameterCard } from "../../components/paramaterCard";
import { populate } from "./DailyAQISlice";
import { info } from "../../components/rangeInfo/AQIDangerRanges";
import { MapMarker } from "../../components/Map/mapmarker";
import { Redirect } from "react-router";
import { changeDevice } from "../../app/InputSlice";
import { AQIBadge } from "../../components/rangeInfo/AQIBadge";
import { getTodaysData } from "../../api/getTodaysData";
import moment from "moment";
import { useState } from "react";

export type DeviceData = {
  device_id: string;
  timestamp: string;
  dust_concentration: string;
};

const DailyAQI: React.FC<{}> = () => {
  const devices = useSelector((state: RootState) => state.devices);
  const daily_data = useSelector((state: RootState) => state.daily);
  // const hourly_data = useAppSelector(s => s.hourly);
  // const firstRow = !!hourly_data && hourly_data.length > 0 ? hourly_data[0] : {int_humi_avg: "N/A",ext_temp_avg: "N/A"};
  // const data = daily_data[0];
  const [data, setData] = useState<DeviceData[]>([]);

  // console.log(devices);
  const dispatch = useAppDispatch();

  const { device } = useSelector((root: RootState) => root.input);

  const selectedHelpText = useMemo(
    () =>
      info.HelpText.map((i) => ({
        AQI_Start: parseInt(i.AQI.split("-")[0]),
        AQI_End: parseInt(i.AQI.split("-")[1]),
        ...i,
      })),
    []
  );
  useEffect(() => {
    // const callAPI = async () => {
    //   if (device === null) return;
    //   console.log("calling api");
    //   const data = await getTodaysData(device.dev_id, moment());
    //   dispatch(populate(data.data.data));
    //   console.log("dispatching data");
    // };
    // callAPI();
  }, [device]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://pj.dustmonitor.distronix.in/api/get_all_station"
        );
        const jsonData = await response.json();
        // console.log(jsonData);
        setData(jsonData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    // Call fetchData every 1 minute (60000 milliseconds)
    const intervalId = setInterval(fetchData, 60000);

    // Clean up the interval to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [device]);

  const { state } = useSelector((state: RootState) => state.user);
  if (state !== "loggedIn") return <Redirect to="/signin" />;
  return (
    <>
      {/* <div
        id="filter"
        className="flex flex-row justify-start items-stretch gap-4 p-4"
      >
        <span className="flex flex-row justify-start items-stretch text-left gap-4 rounded-md bg-gray-300 m-0 p-0">
          <div className="m-auto pl-2 text-center">Devices</div>
          <select
            name="devices"
            id="devices"
            className="p-2 outline-none bg-white rounded-md rounded-l-none placeholder-gray-500"
            defaultValue="null"
            onChange={(e) =>
              setselectedDevice(
                devices.find((d) => d.dev_id === e.currentTarget.value)
              )
            }
            value={selectedDevice?.dev_id}
          >
            <option value="null" disabled>
              Select Any
            </option>
            {devices.map((d) => (
              <option value={d.dev_id}>{d.location}</option>
            ))}
          </select>
        </span>
        <span className="flex flex-row justify-start items-stretch text-left gap-4 rounded-md bg-gray-300 m-0 p-0">
          <div className="m-auto pl-2 text-center">Date</div>
          <input
            type="date"
            name="selectedDate"
            id="selectedDate"
            className="p-2 outline-none bg-white rounded-md rounded-l-none placeholder-gray-500"
            value={selectedDate}
            onChange={(e) => setselectedDate(e.target.value)}
          />
        </span>
        <span className="flex flex-row justify-start items-stretch text-left gap-4 rounded-md bg-gray-300 m-0 p-0">
          <div className="m-auto pl-2 text-center">Hour</div>
          <select
            name="hour"
            id="hour"
            className="p-2 outline-none bg-white rounded-md rounded-l-none placeholder-gray-500"
            defaultValue={defaultHour === 0 ? 23 : defaultHour - 1}
            onChange={(e) => setselectedHour(parseInt(e.currentTarget.value))}
          >
            {Array(24)
              .fill(0)
              .map((i, h) => (
                <option value={h}>{h}</option>
              ))}
          </select>
        </span>
      </div> */}
      {/* {dailyaqi.length > 0 && !!dailyaqi[0] ? (
        <div className="flex flex-row justify-start items-start flex-wrap gap-4 m-4 ">
          <ParameterCard
            name={
              <>
                NO<sub>2</sub>
              </>
            }
            height={150}
            width={300}
            data={{
              avg: String(dailyaqi[0].NO2_AVG),
              max: String(dailyaqi[0].NO2_MAX),
              min: String(dailyaqi[0].NO2_MIN),
            }}
          />
          <ParameterCard
            name={
              <>
                SO<sub>2</sub>
              </>
            }
            height={150}
            width={300}
            data={{
              avg: String(dailyaqi[0].SO2_AVG),
              max: String(dailyaqi[0].SO2_MAX),
              min: String(dailyaqi[0].SO2_MIN),
            }}
          />
          <ParameterCard
            name={<>PM 10</>}
            height={150}
            width={300}
            data={{
              avg: String(dailyaqi[0].PM10_AVG),
              max: String(dailyaqi[0].PM10_MAX),
              min: String(dailyaqi[0].PM10_MIN),
            }}
          />
          <ParameterCard
            name={<>PM 25</>}
            height={150}
            width={300}
            data={{
              avg: String(dailyaqi[0].PM25_AVG),
              max: String(dailyaqi[0].PM25_MAX),
              min: String(dailyaqi[0].PM25_MIN),
            }}
          />
          <ParameterCard
            name={<>Temperature</>}
            height={150}
            width={300}
            data={{
              avg: String(dailyaqi[0].EXT_TEMP_AVG),
              max: String(dailyaqi[0].EXT_TEMP_MAX),
              min: String(dailyaqi[0].EXT_TEMP_MIN),
            }}
          />
          <ParameterCard
            name={<>Humidity</>}
            height={150}
            width={300}
            data={{
              avg: String(dailyaqi[0].EXT_HUMI_AVG),
              max: String(dailyaqi[0].EXT_HUMI_MAX),
              min: String(dailyaqi[0].EXT_HUMI_MIN),
            }}
          />
        </div>
      ) : null} */}
      <div className="flex flex-row flex-1 justify-start items-stretch p-4 gap-4">
        <GoogleMapBox
          options={
            device !== null
              ? {
                  center: {
                    lat: parseFloat(device.lat),
                    lng: parseFloat(device.long),
                  },
                }
              : {}
          }
        >
          <MapMarker
            lat={parseFloat(device?.lat || "0")}
            lng={parseFloat(device?.long || "0")}
            text={device?.location}
            aqi={parseFloat(
              data.find((s) => s.device_id === device?.dev_id)
                ?.dust_concentration || "0"
            )}
            selected
          />

          {/* {!!devices && devices.length > 0
            ? devices
                .filter((d) => d.lat !== null && d.long !== null)
                .map((d) =>
                  device?.dev_id === d.dev_id ? (
                    <MapMarker
                      lat={parseFloat(d.lat || "0")}
                      lng={parseFloat(d.long || "0")}
                      text={d.location}
                      aqi={parseFloat(
                        data.find((s) => s.device_id === d.dev_id)
                          ?.dust_concentration || "0"
                      )}
                      selected
                    />
                  ) : (
                    <MapMarker
                      lat={parseFloat(d.lat)}
                      lng={parseFloat(d.long)}
                      text={d.location}
                      aqi={parseInt(d.aqi)}
                    />
                  )
                )
            : null} */}
        </GoogleMapBox>
        {!!device && !!data ? (
          <>
            <div className="flex flex-1 flex-col justify-start items-stretch pb-4 overflow-hidden rounded-md bg-blend-overlay bg-white bg-opacity-25">
              <div className="flex flex-row justify-start items-stretch w-full bg-blue-400 rounded-md p-4 text-gray-100 font-semibold font-sans text-xl shadow-lg">
                {/* <AQIBadge aqi={dailyaqi[0]?.AQI} /> */}
                {/* {selectedDevice?.location} */}
                <span className="flex flex-row justify-start items-stretch text-left gap-4 shadow-lg rounded-md bg-transparent m-0 p-0">
                  <div className="m-auto pl-2 text-center">Device</div>
                  <select
                    name="devices"
                    id="devices"
                    className="p-2 outline-none bg-transparent rounded-md text-gray-50 border-0 rounded-l-none placeholder-gray-800 border-l-2 border-gray-300"
                    defaultValue="null"
                    onChange={(e) => {
                      const newDevice = devices.find(
                        (d) => d.dev_id === e.currentTarget.value
                      );
                      // console.log('new device ==>',newDevice)
                      if (!!newDevice) dispatch(changeDevice(newDevice));
                    }}
                    value={device?.dev_id}
                  >
                    <option
                      value="null"
                      className="text-gray-100 bg-blue-400"
                      disabled
                    >
                      Select Any
                    </option>
                    {devices.map((d) => (
                      <option
                        value={d.dev_id}
                        className="text-gray-100 bg-blue-400"
                      >
                        {d.location}
                      </option>
                    ))}
                  </select>
                </span>
              </div>

              <div className="bg-gray-800 p-2 m-2 rounded-md">
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-2xl">
                  <div>Device Name: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {device.location}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-xl">
                  <div>Device Location: </div>
                  <div className="text-gray-50 text-lg font-bold px-4">
                    {device.address}
                  </div>
                </div>

                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-2xl">
                  <div>Latitude: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {device.lat}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-2xl">
                  <div>Longitude: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {device.long}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-2xl">
                  <div>Timestamp: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {moment(
                      data[
                        device.location_id === 7
                          ? 0
                          : device.location_id === 8
                          ? 1
                          : 2
                      ]?.timestamp
                    ).format("MMMM Do YYYY, h:mm:ss a")}
                  </div>
                </div>
                {device.location_id === 7 && (
                  <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-xl">
                    <div>Dust Concentration : </div>
                    <div className="text-gray-50 text-lg font-bold px-4">
                      {data[0]?.dust_concentration} mg/m3
                    </div>
                  </div>
                )}
                {device.location_id === 8 && (
                  <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-xl">
                    <div>Dust Concentration : </div>
                    <div className="text-gray-50 text-lg font-bold px-4">
                      {data[1]?.dust_concentration} mg/m3{" "}
                    </div>
                  </div>
                )}
                {device.location_id === 9 && (
                  <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-xl">
                    <div>Dust Concentration : </div>
                    <div className="text-gray-50 text-lg font-bold px-4">
                      {data[2]?.dust_concentration} mg/m3
                    </div>
                  </div>
                )}

                {/* <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-xl">
                  <div>Dust Concentration for klin 1: </div>
                  <div className="text-gray-50 text-lg font-bold px-4">
                    {data[1]?.dust_concentration} mg/m3 at{" "}
                    {moment(data[1]?.timestamp).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </div>
                </div>
                <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-2 text-xl">
                  <div>Dust Concentration for klin 2: </div>
                  <div className="text-gray-50 text-lg font-bold px-4">
                    {data[2]?.dust_concentration} mg/m3 at{" "}
                    {moment(data[2]?.timestamp).format(
                      "MMMM Do YYYY, h:mm:ss a"
                    )}
                  </div>
                </div> */}
                {/* <div className="flex flex-row justify-start items-center text-gray-200 p-4 py-1 text-xl">
                  <div>Temperature: </div>
                  <div className="text-gray-50 text-xl font-bold px-4">
                    {data.ext_temp_avg} °C
                  </div>
                </div> */}
              </div>
              {/* <div className="flex flex-row justify-center items-center m-2">
                <table className="bg-gray-700 w-full table">
                  <thead className="table-header-group">
                    <tr className="table-row">
                      <th className="p-2 py-4 text-white bg-gray-700 mx-1 text-left">
                        Pollutant
                      </th>
                      <th className="p-2 py-4 text-white bg-gray-700 mx-1 text-left">
                        Average
                      </th>
                      <th className="p-2 py-4 text-white bg-gray-700 mx-1 text-left">
                        Minimum
                      </th>
                      <th className="p-2 py-4 text-white bg-gray-700 mx-1 text-left">
                        Maximum
                      </th>
                    </tr>
                  </thead>
                  <tbody className="table-row-group p-2 text-gray-200">
                    <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                      <td className="p-2 py-4">
                        SO<sub>2</sub>
                      </td>
                      <td className="p-2 py-4">
                        {data.so2_avg} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.so2_min} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.so2_max} &#181;g/m<sup>3</sup>
                      </td>
                    </tr>
                    <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                      <td className="p-2 py-4">
                        NO<sub>2</sub>
                      </td>
                      <td className="p-2 py-4">
                        {data.no2_avg} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.no2_min} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.no2_max} &#181;g/m<sup>3</sup>
                      </td>
                    </tr>
                    <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                      <td className="p-2 py-4">PM 2.5</td>
                      <td className="p-2 py-4">
                        {data.pm25_avg} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.pm25_min} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.pm25_max} &#181;g/m<sup>3</sup>
                      </td>
                    </tr>
                    <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                      <td className="p-2 py-4">PM 10</td>
                      <td className="p-2 py-4">
                        {data.pm10_avg} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.pm10_min} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.pm10_max} &#181;g/m<sup>3</sup>
                      </td>
                    </tr>
                    <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                      <td className="p-2 py-4">PM 1</td>
                      <td className="p-2 py-4">
                        {data.pm1_avg} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.pm1_min} &#181;g/m<sup>3</sup>
                      </td>
                      <td className="p-2 py-4">
                        {data.pm1_max} &#181;g/m<sup>3</sup>
                      </td>
                    </tr>
                    <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                      <td className="p-2 py-4">Temperature</td>
                      <td className="p-2 py-4">{data.ext_temp_avg} &#176;C</td>
                      <td className="p-2 py-4">{data.ext_temp_min} &#176;C</td>
                      <td className="p-2 py-4">{data.ext_temp_max} &#176;C</td>
                    </tr>
                    <tr className="p-2 table-row even:bg-gray-600 odd:bg-gray-800">
                      <td className="p-2 py-4">Humidity</td>
                      <td className="p-2 py-4">{data.int_humi_avg} %</td>
                      <td className="p-2 py-4">{data.int_humi_min} %</td>
                      <td className="p-2 py-4">{data.int_humi_max} %</td>
                    </tr>
                  </tbody>
                </table>
              </div> */}
            </div>
          </>
        ) : null}
        {/* <div className="flex flex-1 flex-row justify-start items-end text-lg font-sans font-semibold p-4 w-full gap-4">
            <div className="flex flex-col justify-end items-center gap-2">
              <span
                className="rounded-full p-8 shadow-lg bg-gray-100 text-gray-600 font-sans font-bold text-center flex flex-col justify-center items-center text-xl hover:bg-gray-600 hover:text-gray-100 transition-colors duration-200"
                style={{ width: 100, height: 100 }}
              >
                <FaBatteryFull size="60px" />
              </span>
              Battery
            </div>
            <div className="flex flex-col justify-end items-center gap-2">
              <span
                className="rounded-full p-8 shadow-lg bg-gray-100 text-gray-600 font-sans font-bold text-center flex flex-col justify-center items-center text-xl hover:bg-gray-600 hover:text-gray-100 transition-colors duration-200"
                style={{ width: 100, height: 100 }}
              >
                <GiPlug size="60px" />
              </span>
              Power
            </div>
            <div className="flex flex-col justify-end items-center gap-2">
              <span
                className="rounded-full p-8 shadow-lg bg-gray-100 text-gray-600 font-sans font-bold text-center flex flex-col justify-center items-center text-xl hover:bg-gray-600 hover:text-gray-100 transition-colors duration-200"
                style={{ width: 100, height: 100 }}
              >
                <MdSignalCellular4Bar size="60px" />
              </span>
              Network
            </div>
            <div className="flex flex-row justify-end items-order flex-1">
              <button className="p-2 rounded-md shadow-md bg-blue-400 text-gray-100">
                Get More Diagnostic Data
              </button>
            </div>
          </div> */}
      </div>
    </>
  );
};

export default DailyAQI;
