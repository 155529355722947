import React, { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import googleMapReact from "google-map-react";

export const GoogleMapBox: React.FC<{
  options?: Partial<googleMapReact.Props>;
}> = ({ children, options }) => {
  const mapref = useRef<GoogleMapReact | null>(null);

  return (
    <div className="w-5/12 max-h-full">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyC6IjsKHi8RLOzm7oGGS9vw0uYQcs87nYA" }}
        defaultCenter={{ lat: 22.56, lng: 88.47 }}
        defaultZoom={17}
        zoom={17}
        resetBoundsOnResize={true}
        ref={(r) => (mapref.current = r)}
        style={{
          height: "100%",
        }}
        {...options}
      >
        {children}
      </GoogleMapReact>
    </div>
  );
};

export const GoogleMapBoxMemoized = React.memo(GoogleMapBox);
