import { getDeviceList_returns_data_item } from "../getDeviceList";

export const HardCodedDevices: getDeviceList_returns_data_item[] = [
  {
    id: 1,
    lat: "23.4690343",
    location: "SD Stack",
    long: "87.454669",
    dev_id: "1348380",
    location_id: 7,
    district: "Kolkata",
    aqi: "0",
    stncode: "",
    last_online: "",
    address:
      "3No. Collony, Paschim, Kanksa, Panagarh, West Bengal 713148, India",
  },
  {
    id: 2,
    lat: "23.4706756",
    location: "Kiln 1",
    long: "87.4606248",
    dev_id: "4175676",
    location_id: 8,
    district: "Kolkata",
    aqi: "0",
    stncode: "",
    last_online: "",
    address: "Kanksa, West Bengal, India",
  },
  {
    id: 3,
    lat: "23.4706807",
    location: "Kiln 2",
    long: "87.4605862",
    dev_id: "4177604",
    location_id: 9,
    district: "Kolkata",
    aqi: "0",
    stncode: "",
    last_online: "",
    address: "Kanksa, West Bengal, India",
  },
];
