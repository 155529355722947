import React from "react";

import { FaMapMarkerAlt } from "react-icons/fa";

export const MapMarker: React.FC<{
  lat: number;
  lng: number;
  text: string | undefined;
  aqi: number;
  selected?: boolean;
}> = ({ text, selected, aqi }) => {
  return (
    <>
      <div
        className="hover:text-gray-800 text-transparent font-bold hover:shadow-lg text-center cursor-pointer"
        data-tip={text}
        // onClick={onClick}
      >
        <span
          className="animate-ping absolute inline-flex h-7 w-7 rounded-full opacity-40"
          style={{ backgroundColor: "#8EBBED" }}
        ></span>
        <div
          className={`flex flex-row justify-center items-center z-10 rounded-full w-10 h-10 text-gray-200 border-2 border-white ${
            !!selected ? "" : ""
          }`}
          style={{ background: "#8EBBED" }}
        >
          <p className="-pt-1 text-gray-900">{aqi}</p>
        </div>
        {/* <ReactTooltip /> */}
      </div>
    </>
  );
};

export const MapMarkerMemoized = React.memo(MapMarker);
